<template>
  <div style="padding: 20px">
    <!-- Loader -->
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col md="12" class="py-0">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <!-- Bottone di creazione commessa singola -->
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Commessa <v-icon color="white">mdi-file-cog-outline</v-icon>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="COMMESSA"
                  idOggetto="CREA_COMMESSA"
                  mdi_icona="mdi-plus"
                  testo_tooltip="Nuovo Commessa"
                  v-on:buttonClick="GoToCommessa"
                />
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="COMMESSA"
                  idOggetto="CREA_COMMESSA_MULTIPLA"
                  mdi_icona="mdi-expand-all"
                  testo_tooltip="Nuova Commessa Multipla"
                  v-on:buttonClick="GoToCommessaMultipla()"
                />
                <!-- Bottone di ricerca -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      v-on:keydown.enter.prevent="getCommessa"
                      @click="getCommessa"
                      small
                    >
                      <v-icon color="primary">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Lista Commesse</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <!-- Bottone di cambiamento stato commessa -->
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="COMMESSA"
                  idOggetto="GESTIONE_COMMESSA_STATO"
                  mdi_icona="mdi-order-bool-ascending-variant"
                  testo_tooltip="Gestione Stato"
                  v-on:buttonClick="
                    items.length > 0
                      ? (cambia_stato_sel = !cambia_stato_sel)
                      : (cambia_stato_sel = false)
                  "
                />
                <!-- Bottone di invio modifiche dello stato commessa -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!(cambia_stato_sel === true && items.length > 0)"
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="modificaStatoCommessa"
                      class="mr-5"
                    >
                      <v-icon color="primary">mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span>Invia Modifiche di Stato</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="gotoDashboard" small>
                      <v-icon color="error">mdi-keyboard-backspace</v-icon>
                    </v-btn>
                  </template>
                  <span>Vai alla Dashboard</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-subtitle>
                <v-row justify="center">
                  <v-col class="py-0" md="3">
                    <!-- Filtro dalla data -->
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDate"
                          label="Dalla Data"
                          placeholder="Dalla Data"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_da"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" md="3">
                    <!-- Filtro entro la data -->
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDaAa"
                          label="Entro la Data"
                          placeholder="Entro la Data"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_a"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" md="4">
                    <!-- Filtro stato commessa -->
                    <v-autocomplete
                      v-on:keydown.enter.prevent="getCommessa"
                      v-model="des_stato"
                      :items="des_stato_list"
                      item-text="des"
                      label="Filtra per Stato Commessa"
                      placeholder="Stato Commessa"
                      required
                      item-value="val-txt"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="items.length > 0" justify="center">
                  <v-col class="py-0" md="4" v-if="cambia_stato_sel === true">
                    <!-- Select di modifica dello stato -->
                    <v-autocomplete
                      v-model="stato_sel"
                      :items="des_stato_list"
                      item-text="des"
                      label="Cambia in Stato..."
                      placeholder="Stato Commessa"
                      required
                      item-value="id_tabella"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Filtro di ricerca stringa -->
                  <v-col class="py-0" md="4" justify="center">
                    <v-text-field
                      style="width: 300px !important"
                      v-model="search"
                      label="Cerca nella Tabella"
                      single-line
                      hide-details
                    >
                      <v-icon slot="append" color="primary"> mdi-magnify </v-icon>
                    </v-text-field></v-col
                  >
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  fixed-header
                  height="55vh"
                >
                  <!-- Colonna per la selezione di stato -->
                  <template
                    v-slot:[`item.edit_stato`]="{ item }"
                    v-if="cambia_stato_sel === true"
                  >
                    <v-checkbox v-model="item.edit_stato"></v-checkbox>
                  </template>
                  <!-- Colonna per l'azione di modifica -->
                  <template v-slot:[`item.edit`]="{ item }">
                    <Button
                      v-if="cambia_stato_sel === false"
                      classe_bottone="ml-2"
                      colore_icona="primary"
                      idForm="COMMESSA"
                      idOggetto="MODIFICA_COMMESSA"
                      mdi_icona="mdi-pencil"
                      testo_tooltip="Dettaglio Commessa"
                      v-on:buttonClick="handleClick(item)"
                    />
                  </template>
                  <!--<template v-slot:[`item.datacommessa`]="{ item }">
                    {{
                      item.datacommessa != null
                        ? `${item.datacommessa.substring(
                            8,
                            10
                          )}/${item.datacommessa.substring(
                            5,
                            7
                          )}/${item.datacommessa.substring(0, 4)}`
                        : ""
                    }}
                  </template>
                  <template v-slot:[`item.dataconsegna`]="{ item }">
                    {{
                      item.datacommessa != null
                        ? `${item.dataconsegna.substring(
                            8,
                            10
                          )}/${item.dataconsegna.substring(
                            5,
                            7
                          )}/${item.dataconsegna.substring(0, 4)}`
                        : ""
                    }}
                  </template>-->
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbar_background" :timeout="3000">
      <span
        :style="{
          fontSize: snackbar_font_size,
          lineHeight: snackbar_font_size,
        }"
      >
        {{ snackbar_text }}
      </span>
    </v-snackbar>
  </div>
</template>
<script>
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import * as moment from "moment/moment";
export default {
  components: { Button },
  data() {
    return {
      cambia_stato_sel: false,
      stato_sel: null,
      // Overlay Variable
      overlay: false,
      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_color: "",
      snackbar_font_size: "",
      snackbar_text: "",
      snackbar_sfondo: "",
      show: true,
      search: "",
      menu: false,
      menu1: false,
      data_da: "",
      data_a: "",
      id_commessa: 0,
      id_stato: 0,
      des_stato: "Aperta",
      des_stato_list: [],
      headers: [],
      items: [],
    };
  },
  methods: {
    // In order to go to dashboard
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    // In order to go to new Commessa form
    GoToCommessa() {
      this.$router.push(`/home/production/commesa/-1`);
    },
    GoToCommessaMultipla() {
      this.$router.push(`/home/production/commessamultipla/-1`);
    },
    // in order to go to Commessa Detail
    handleClick(val) {
      this.$router.push(`/home/production/commesa/${val.id_commessa}`);
    },
    // In order to get commessa Lista
    getCommessa() {
      this.overlay = true;
      /* Riporto a false la variabile "cambia_stato_sel",
       * in caso l'utente l'avesse modificata, per ripristinare
       * lo stato originale. */
      this.cambia_stato_sel = false;
      // var des_stato = "";
      if (this.cambia_stato_sel == true) {
        this.cambia_stato_sel = false;
      }
      this.des_stato_list.map((it) => {
        if (this.des_stato === it.des) {
          this.id_stato = it.id_tabella;
        }
      });
      let getcommessa = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessa",
          data_da: this.data_da,
          data_a: this.data_a,
          id_profilo: localStorage.getItem("id_profilo"),
          token: localStorage.getItem("user_token"),
          id_commessa: "",
          id_articolo: "",
          id_stato: this.des_stato === "" ? null : this.id_stato,
        },
      };
      this.$store.dispatch("api", getcommessa).then((res) => {
        this.overlay = false;
        this.items = res.data.lista;
        console.log(this.items);
        let data = {
          value: "edit",
          sortable: false,
        };
        if (res.data.header != undefined) {
          this.headers = res.data.header;
          this.headers.unshift(data);
        }
        /* Se l'utente vuole modificare lo stato delle
         * commesse, aggiungo una colonna edit_stato. */
        if (this.cambia_stato_sel === true) {
          let edit_stato = {
            value: "edit_stato",
            sortable: false,
            text: "Selezione",
          };
          this.headers.unshift(edit_stato);
        }
        this.items.map((it) => (it.delete = ""));
        this.items.map((it) => (it.edit = ""));
        this.items.map((it) => (it.edit_stato = false));
        if (this.items.length > 0) {
          this.show = false;
        }
      });
    },
    modificaStatoCommessa() {
      /* Se l'utente non ha selezionato uno stato
       * stampo una snackbar di errore. */
      this.overlay = true;
      if (this.stato_sel == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_font_size = 40 + "px";
        this.snackbar_text = "Selezionare uno stato.";
      } else {
        let tabella = [];
        this.items.forEach((elem) => {
          if (elem.edit_stato == true) {
            elem.edit_stato = false;
            elem.des_stato = this.stato_sel;
            tabella.push(elem.id_commessa);
          }
        });
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setcommessestato",
            token: localStorage.getItem("user_token"),
            tabella: tabella,
            id_tabella: this.stato_sel,
          },
        };
        this.$store.dispatch("api", request).then(() => {
          this.overlay = false;
          // Disabilito la modifica multipla di stato e svuoto gli headers.
          this.cambia_stato_sel = false;
          this.headers = [];
          // Aggiorno la tabella; questa azione ripopola gli headers.
          this.getCommessa();
        });
      }
    },
  },
  mounted() {
    // In order to get commessa stato list
    let stato_list = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'commessa_stato','DATA_COMMESSA'",
      },
    };
    this.$store.dispatch("api", stato_list).then((res) => {
      this.des_stato_list = res.data.COMMESSA_STATO ? res.data.COMMESSA_STATO : [];
      let i =
        res.data.DATA_COMMESSA != undefined ? res.data.DATA_COMMESSA[0].val_int / 30 : 1;
      let adesso = moment();
      let date_string1 = moment(adesso).subtract(i, "months").format("YYYY-MM-DD");
      this.data_da = date_string1;
      this.getCommessa();
    });
  },
  computed: {
    formatDate() {
      return this.data_da ? moment(this.data_da).format("DD/MM/YYYY") : "";
    },
    formatDaAa() {
      return this.data_a ? moment(this.data_a).format("DD/MM/YYYY") : "";
    },
  },
  watch: {
    cambia_stato_sel: {
      immediate: true,
      handler() {
        // Se l'elenco commesse è vuoto, non faccio niente.
        if (this.items.length === 0) {
          return;
        }
        /* Se l'utente vuole modificare lo stato delle
         * commesse, aggiungo una colonna edit_stato. */
        if (this.cambia_stato_sel === true) {
          let edit_stato = {
            value: "edit_stato",
            sortable: false,
            text: "Selezione",
          };
          this.headers.unshift(edit_stato);
          /* Elimino l'header per la modifica */
          let headers_tmp = this.headers.filter((elem) => elem.value != "edit");
          this.headers = [];
          this.headers = headers_tmp;
          /* Elimino l'header per la cancellazione */
          headers_tmp = [];
          headers_tmp = this.headers.filter((elem) => elem.value != "delete");
          this.headers = [];
          this.headers = headers_tmp;
        } else {
          /* Se invece l'utente non vuole modificare lo
           * stato delle commesse, rimuovo la colonna relativa. */
          let headers_tmp = this.headers.filter((elem) => elem.value != "edit_stato");
          this.headers = [];
          this.headers = headers_tmp;
          /* Aggiungo l'header per la modifica. */
          let edit = {
            value: "edit",
            sortable: false,
          };
          this.headers.unshift(edit);
          /* Aggiungo l'header per la cancellazione. */
          let del = {
            value: "delete",
            sortable: false,
          };
          this.headers.push(del);
        }
      },
    },
  },
};
</script>
